import { createApp } from 'vue'
import App from './App.vue'
import '@/assets/icon'
import SvgIcon from '@/assets/icon/index.vue'
import router from '@/router/index'
import '@/assets/font/index.css'
import 'animate.css';
import directives from '@/directives'
const app = createApp(App)
app.use(router)
directives(app)
app.component('svg-icon', SvgIcon)
app.mount('#app')
