<script>
import VIPClub from "@/components/Home/VIPClub.vue";
import ContactMain from "@/components/Home/ContactMain.vue";
export default {
  name: "HomeMain",
  components: {ContactMain, VIPClub},
  data(){
    return{
      contact_show:true,
    }
  },
  mounted() {
  }
}
</script>

<template>
<div class="home-main">
  <div id="vip">
    <VIPClub></VIPClub>
  </div>
  <div id="contact" v-show="contact_show">
    <ContactMain></ContactMain>
  </div>
</div>
</template>

<style scoped>
.home-main{

}
</style>