<script>
import SvgIcon from "@/assets/icon/index.vue";
import {star_up_data} from "@/api/updata";

export default {
  name: "WorkFlowOrderReviewOne",
  components: {SvgIcon},
  data(){
    return{
      star_state: 0,
      review_title: "",
      star_click: 0
    }
  },
  props: ["order"],
  computed: {
    star_color(){
      return (id)=>{
        return this.star_state >= id? "#FE8702" : "white"
      }
    }
  },
  methods: {
    prev_step(){
      this.$emit("leftClick")
    },
    async next_step(){
      try {
        await star_up_data({star:this.star_state,message:this.review_title,query_id:this.order.query_id})
        this.$emit("rightClick",{star:this.star_state,message:this.review_title})
      }catch(err){
        console.log(err)
      }
    }
  }
}
</script>

<template>
<div class="work-flow-order-review-one-main">
  <span class="work-flow-order-review-one-title">Step 1 of 3: Tell Us Your Experience About It</span>
  <div class="work-flow-order-review-one-divider">
    <div></div>
  </div>
  <div class="work-flow-order-review-one-details">
    <div class="title-img">
      <span>{{order.product_name}}</span>
      <img :src="order.img_listing">
    </div>
    <div class="operation">
      <span>Overall rating?*</span>
      <div class="work-flow-order-review-one-star" @mouseleave="star_state = star_click">
        <div v-for="(item,index) in [1,2,3,4,5]" :style="`color: ${star_color(item)};`" :key="index">
          <svg-icon icon-class="Star" @mouseenter="star_state = item" @click="star_click = item"></svg-icon>
        </div>
      </div>
      <span>Let's know your comment about the product.*</span>
      <textarea cols="10" placeholder="Please enter at least 4 characters." v-model="review_title"></textarea>
    </div>
  </div>
  <div class="work-flow-order-review-one-button">
    <div @click="prev_step">PREV STEP</div>
    <div @click="next_step">NEXT STEP</div>
  </div>
</div>
</template>

<style scoped>
.work-flow-order-review-one-main{
  display: flex;
  flex-direction: column;
  background-color: #8c8c95;
  padding: 30rem;
  border-radius: 15rem;
}
.work-flow-order-review-one-title{
  font-size: 30rem;
}
.work-flow-order-review-one-divider{
  background-color: white;
  height: 3rem;
  margin: 20rem 0;
}
.work-flow-order-review-one-divider > div{
  height: 100%;
  width: 33%;
  background-color: #FE8702;
}
.work-flow-order-review-one-details{
  display: flex;
  padding: 0 30rem;
}
.work-flow-order-review-one-details .title-img{
  width: 250rem;
  box-sizing: border-box;
  background-color: white;
  border-radius: 20rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30rem 0;
  margin-right: 50rem;
}
.work-flow-order-review-one-details .title-img span{
  width: 200rem;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.work-flow-order-review-one-details .title-img img{
  height: 200rem;
  margin-top: 10rem;
}
.work-flow-order-review-one-details .operation{
  display: flex;
  flex: 1;
  flex-direction: column;
}
.work-flow-order-review-one-details .operation span{
  font-weight: 550;
  font-size: 21rem;
}
.work-flow-order-review-one-star{
  display: flex;
  font-size: 40rem;
  margin: 20rem 0;
}
.work-flow-order-review-one-star svg{
  cursor: pointer;
}
.work-flow-order-review-one-star > div{
  margin-right: 15rem;
}
.work-flow-order-review-one-details .operation textarea{
  width: 700rem;
  height: 200rem;
  background-color: white;
  padding: 5rem;
  margin-top: 20rem;
}
.work-flow-order-review-one-button{
  display: flex;
  justify-content: space-around;
  margin-top: 30rem;
}
.work-flow-order-review-one-button div{
  background-image: linear-gradient(to bottom,#FCB211,#FE8702);
  padding: 10rem 40rem;
  border-radius: 10rem;
  user-select: none;
  cursor: pointer;
}
@media (max-width: 600px) or (max-aspect-ratio:1/1) {
  .work-flow-order-review-one-details{
    padding: 0 5rem;
    flex-direction: column;
    align-items: center;
  }
  .work-flow-order-review-one-details .title-img{
    width: 90%;
    flex-direction: row;
    justify-content: space-around;
  }
  .work-flow-order-review-one-details .title-img span{
    width: 45%;
  }
  .work-flow-order-review-one-details .title-img img{
    width: 45%;
    height: auto;
  }
  .work-flow-order-review-one-details .operation textarea{
    width: 400rem;
    height: 150rem;
  }
  .work-flow-order-review-one-star{
    margin: 5rem 0;
  }
}
</style>