import request from "@/utils/request_python";

export function code_img(time,code) {
    return request({
        url: `/code/captcha?${time}`,
        method: 'get',
        responseType:'blob',
        params:{
            code
        }
    })
}
export function vipClub(data){
    return request({
        url: '/vipclub',
        method: 'post',
        data
    })
}
export function contact(data){
    return request({
        url: '/contact',
        method: 'post',
        data
    })
}