<script>
import {code_img, vipClub} from "@/api/from";
import countries_all from "@/components/Home/countries";
import tooltip from "@/utils/common/tooltip";
import LoadIcon from "@/common/LoadIcon.vue";
import alert from "@/utils/common/alert";

export default {
  name: "VIPClub",
  components: {LoadIcon},
  data(){
    return{
      name: "",
      email: "",
      countries: "United States",
      code_img: "",
      countries_all,
      code_value: "",
      code_class: "vipclub",
      canClick: true,
      gender: "",
      roles: {
        name: "",
        email: "",
        countries: "",
        code_value: "",
      },
      pending: false,
      lanjie: false
    }
  },
  created() {
    setTimeout(async ()=>{
      await this.img_reload()
    },200)
  },
  methods: {
    role_name(){this.roles.name = this.name !== ""},
    role_email(){this.roles.email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email)},
    role_countries(){this.roles.countries = this.countries !== ""},
    role_code_value(){this.roles.code_value = this.code_value !== ""},
    roles_fun(){
      this.role_name()
      if (!this.roles.name) return {state: false,message: "Please enter your name"}
      this.role_email()
      if (!this.roles.email) return {state: false,message: "Please enter a valid e-mail address"}
      this.role_countries()
      if (!this.roles.countries) return {state: false,message: "Please select a country"}
      this.role_code_value()
      if (!this.roles.code_value) return {state: false,message: "Please enter the verification code"}
      return {state: true}
    },
    async img_reload(){
      try {
        if (this.code_img !== ''){window.URL.revokeObjectURL(this.code_img)}
        const data = await code_img(new Date().getTime(),this.code_class)
        this.code_img = window.URL.createObjectURL(data)
      }catch (error){
        console.log(error)
      }
    },
    async click_img(){
      if(!this.clickHandler())
        await this.img_reload()
    },
    async join_now(event){
      if (this.lanjie) return;
      const yz = this.roles_fun()
      if (!yz.state){tooltip(yz.message,event);return ;}
      if (this.clickHandler())return;
      this.pending = true
      this.lanjie = true
      try {
        await vipClub({name:this.name,email:this.email,gender:this.gender,countries:this.countries,code_value:this.code_value})
        this.pending = false
        alert({value:"Submitted successfully!", state:"success"})
        setTimeout(()=>{
          this.lanjie = false
        }, 5000)
      }catch (error){
        console.log(error)
        this.pending = false
        this.lanjie = false
      }
      try {
        await this.img_reload()
      }catch (error){
        console.log(error)
      }
    },
    clickHandler() {
      if (!this.canClick) return !this.canClick; // 已经触发过点击事件
      // 点击事件逻辑
      this.canClick = false // 将标志变量设为 false
      setTimeout(() => {
        this.canClick = true // 三秒后将标志变量设为 true
      }, 2000)
      return this.canClick;
    }
  }
}
</script>

<template>
<div style="width: 100%;display: flex;justify-content: center;">
  <div class="vip-club-main">
    <span class="text-font-35">WARRANTY EXTENSION,FREE RETURN SHIPPING & More</span>
    <span class="text-font-50">JOIN THE FREE VIP CLUB</span>
    <span class="text-color-174">Welcome to our VIP Club</span>
    <span class="text-color-174">Our VIP benefits:</span>
    <span class="text-color-174">RECEIVE OUR LATEST UPDATES.</span>
    <span class="text-color-174">Hassie-Free Warranty</span>
    <span class="text-color-174">Lifetime Customer Support</span>
    <div class="vip-club-input-div">
      <input class="vip-club-input"
             placeholder="Name"
             type="text"
             v-model="name"
             @blur="role_name"
             :style="`border: ${roles.name === false? 'red 4' : 'rgb(144,144,144) 1'}rem solid;`"/>
      <input class="vip-club-input"
             placeholder="Email"
             type="text" v-model="email"
             @blur="role_email"
             :style="`border: ${roles.email === false? 'red 4' : 'rgb(144,144,144) 1'}rem solid;`"/>
    </div>
    <div class="vip-club-input-div">
      <select class="vip-club-input"
              v-model="countries"
              @blur="role_countries"
              :style="`border: ${roles.countries === false? 'red 4' : 'rgb(144,144,144) 1'}rem solid;`">
        <option v-for="(item,index) in countries_all" :value="item" :key="index">{{item}}</option>
      </select>
    </div>
    <div style="width: 100%;margin: 30rem 0;">
      <span class="text-color-174">*We don't share your personal info with anyone.</span>
    </div>
    <div style="display: flex;width: 100%;">
      <input class="vip-club-input vip-club-input2"
             type="text"
             @blur="role_code_value"
             :style="`border: ${roles.code_value === false? 'red 4' : 'rgb(144,144,144) 1'}rem solid;`"
             placeholder="VerifyCode"
             v-model="code_value"/>
      <img class="vip-club-img" :src="code_img" alt="" @click="click_img">
    </div>
    <div class="vip-club-button" @click="join_now">
      Join now
      <LoadIcon v-show="pending"></LoadIcon>
    </div>
  </div>
</div>
</template>

<style scoped>
.vip-club-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(238,238,238);
  padding: 80rem 40rem;
  margin-top: 70rem;
  margin-bottom: 100rem;
}
.text-font-35{
  font-size: 35rem;
  color: rgb(120,120,120);
}
.text-font-50{
  font-size: 50rem;
}
.text-color-174{
  color: rgb(174,174,174)
}
.vip-club-input-div{
  display: flex;
  margin-top: 40rem;
  width: 100%;
}
.vip-club-input-div > *:first-child{
  margin-right: 50rem;
}
.vip-club-input{
  width: 700rem;
  height: 55rem;
  padding: 0 15rem;
  line-height: 55rem;
  background-color: white;
  box-sizing: border-box;
}
.vip-club-input2{
  width: 750rem;
}
.vip-club-img{
  height: 55rem;
  margin-left: 20rem;
  cursor: pointer;
}
.vip-club-button{
  width: 100%;
  background-image: linear-gradient(to bottom,black, #433232, black);
  color: white;
  height: 60rem;
  line-height: 60rem;
  text-align: center;
  font-size: 30rem;
  border-radius: 30rem;
  margin-top: 50rem;
  user-select: none;
  cursor: pointer;
}
.vip-club-button:hover{
  background-image: linear-gradient(to bottom, #322e2e, #494242, #322e2e);
}
.vip-club-button:active{
  background-image: linear-gradient(to bottom,black, #433232, black);
}
@media (max-width: 600px) or (max-aspect-ratio:1/1) {
  .text-font-35{
    text-align: center;
  }
  .text-font-50{
    font-size: 45rem;
    text-align: center;
  }
  .vip-club-input {
    min-width: 200rem;
    width: 48%;
    font-size: 25rem;
  }
  .vip-club-input2{
    min-width: 300rem;
  }
}
</style>