<script>
import {code_img, contact} from "@/api/from";
import tooltip from "@/utils/common/tooltip";
import EventBus from "@/utils/eventBus";
import LoadIcon from "@/common/LoadIcon.vue";
import alert from "@/utils/common/alert";

export default {
  name: "ContactMain",
  components: {LoadIcon},
  data(){
    return{
      name: "",
      email: "",
      message: "",
      code: "",
      code_img: "",
      code_class: "contact",
      canClick: true,
      roles: {
        name: "",
        email: "",
        message: "",
        code: "",
        query_id: ""
      },
      pending: false,
      lanjie: false
    }
  },
  async created() {
    // setTimeout(async ()=>{
    //   await this.img_reload()
    // },1000)
  },
  mounted() {
    EventBus.$on("contact_updata",(data)=>{
      this.name = data.name
      this.email = data.email
      this.message = data.message
    })
    EventBus.$on("contact_query_id",(query_id)=>{
      this.query_id = query_id
    })
  },
  methods: {
    role_name(){this.roles.name = this.name !== ""},
    role_email(){this.roles.email = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.email)},
    role_message(){this.roles.message = this.message !== ""},
    // role_code(){this.roles.code = this.code !== ""},
    roles_fun(){
      this.role_name()
      if (!this.roles.name) return {state: false,message: "Please enter your name"}
      this.role_email()
      if (!this.roles.email) return {state: false,message: "Please enter a valid e-mail address"}
      this.role_message()
      if (!this.roles.message) return {state: false,message: "Please enter the message to be sent"}
      // this.role_code()
      // if (!this.roles.code) return {state: false,message: "Please enter the verification code"}
      return {state: true}
    },
    async img_reload() {
      try {
        if (this.code_img !== ''){window.URL.revokeObjectURL(this.code_img)}
        const data = await code_img(new Date().getTime(),this.code_class)
        this.code_img = window.URL.createObjectURL(data)
        this.$refs.alert.showAlert("success")
      }catch (error){
        console.log(error)
      }
    },
    async click_img(){
      if(!this.clickHandler())
        await this.img_reload()
    },
    async send_message(event) {
      if (this.lanjie) return;
      const yz = this.roles_fun()
      if (!yz.state){tooltip(yz.message,event);return ;}
      if (this.clickHandler())return;
      this.pending = true
      this.lanjie = true
      try {
        await contact({name:this.name,email:this.email,message:this.message,code:this.code,query_id:this.query_id})
        await this.img_reload()
        this.pending = false
        alert({value:"Submitted successfully!", state:"success"})
        setTimeout(()=>{
          this.lanjie = false
        }, 5000)
      }catch (error){
        await this.img_reload()
        console.log(error)
        this.pending = false
        this.lanjie = false
      }
    },
    clickHandler() {
      if (!this.canClick) return !this.canClick; // 已经触发过点击事件
      // 点击事件逻辑
      this.canClick = false // 将标志变量设为 false
      setTimeout(() => {
        this.canClick = true // 三秒后将标志变量设为 true
      }, 2000)
      return this.canClick;
    }
  }
}
</script>

<template>
<div style="width: 100%;display: flex;justify-content: center;">
  <div class="contact-main">
    <span style="font-size: 50rem">Contact</span>
    <div class="contact-input">
      <input placeholder="Name"
             @blur="role_name"
             :style="`border: ${roles.name === false? 'red 4' : 'rgb(144,144,144) 1'}rem solid;`"
             v-model="name">
      <input placeholder="E-mail"
             @blur="role_email"
             :style="`border: ${roles.email === false? 'red 4' : 'rgb(144,144,144) 1'}rem solid;`"
             v-model="email">
    </div>
    <textarea class="contact-message"
              placeholder="Message"
              @blur="role_message"
              :style="`border: ${roles.message === false? 'red 4' : 'rgb(144,144,144) 1'}rem solid;`"
              v-model="message"></textarea>
    <div style="width: 100%;color: rgb(174,174,174);margin: 30rem 0;">
      <span>If you have any questions about the order product,please provide the order number.</span>
    </div>
    <!--  <div class="contact-code">-->
    <!--    <input placeholder="VerifyCode"-->
    <!--           @blur="role_code"-->
    <!--           :style="`border: ${roles.code === false? 'red 4' : 'rgb(144,144,144) 1'}rem solid;`"-->
    <!--           v-model="code">-->
    <!--    <img :src="code_img" alt="" @click="click_img">-->
    <!--  </div>-->
    <div class="contact-button" @click="send_message">
      <LoadIcon v-show="pending"></LoadIcon>
      Send message
    </div>
  </div>
</div>
</template>

<style scoped>
.contact-main{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80rem 40rem;
  box-shadow: 0 0 3rem 1rem rgba(225,225,225);
  margin-top: 70rem;
  margin-bottom: 180rem;
}
.contact-input{
  display: flex;
  margin: 40rem 0;
  min-width: 1450rem;
  justify-content: space-between;
}
.contact-input input{
  min-width: 700rem;
  width: 48%;
  height: 55rem;
  padding: 0 15rem;
  line-height: 55rem;
  background-color: white;
  box-sizing: border-box;
}
.contact-message{
  width: 100%;
  height: 300rem;
  padding: 10rem;
  box-sizing: border-box;
  background-color: white;
}
.contact-code{
  display: flex;
  width: 100%;
}
.contact-code input{
  height: 55rem;
  padding: 0 15rem;
  line-height: 55rem;
  background-color: white;
  box-sizing: border-box;
  margin-right: 50rem;
}
.contact-code img{
  height: 55rem;
  margin-left: 20rem;
  cursor: pointer;
}
.contact-button{
  width: 100%;
  background-color: black;
  color: white;
  height: 60rem;
  line-height: 60rem;
  text-align: center;
  font-size: 30rem;
  border-radius: 30rem;
  margin-top: 50rem;
  user-select: none;
  cursor: pointer;
}
.contact-button:hover{
  background-image: linear-gradient(to bottom, #322e2e, #494242, #322e2e);
}
.contact-button:active{
  background-image: linear-gradient(to bottom,black, #433232, black);
}
@media (max-width: 600px) or (max-aspect-ratio:1/1) {
  .contact-input{
    min-width: 450rem;
    width: 100%;
  }
  .contact-input input{
    min-width: 200rem;
    font-size: 25rem;
  }
  .contact-message{
    height: 300rem;
    font-size: 25rem;
  }
}
</style>