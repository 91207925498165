<template>
  <div class="selection-box">
    <div class="top-branding"><a href="/home">Active Warranty<img src="" alt=""></a></div>
    <div class="navigator-title">
      <div class="top-button" @click="work_state = true">WARRANTY</div>
      <div class="selection-box-div" @click="work_state = true" style="cursor: pointer;padding: 0 20rem;">Home</div>
<!--      <div v-for="(item,index) in top_All" :key="index" style="cursor: pointer;">-->
<!--        <router-link :to="item.path">-->
<!--          <div class="selection-box-div">-->
<!--            <span>{{item.meta.title}}</span>-->
<!--          </div>-->
<!--        </router-link>-->
<!--      </div>-->
      <div v-for="(item,index) in top_All" :key="index" style="cursor: pointer;" @click="detailed_information(item.id)">
        <div class="selection-box-div">
          <span>{{item.title}}</span>
        </div>
      </div>
    </div>
    <div class="navigator-mobile">
      <svg-icon icon-class="navigator" @click="this.mobile_height = true"></svg-icon>
    </div>
    <div class="selection-mobile" :style="`left: ${mobile_left}%`">
      <div class="top-branding">Active Warranty<img src="" alt=""></div>
      <div class="top-button" @click="mobile_height=false;work_state = true">WARRANTY</div>
      <div class="mobile-box-div" @click="mobile_height=false;work_state = true">Home</div>
      <div v-for="(item,index) in top_All" :key="index" @click="mobile_height=false;detailed_information(item.id);">
        <div class="mobile-box-div">
          <span>{{item.title}}</span>
        </div>
      </div>
    </div>
    <div v-show="mobile_height" class="work-flow-shields" style="z-index: 7;" @click="this.mobile_height = false"></div>
    <div v-show="work_state" class="work-flow-top">
      <WorkFlowMain @closeWork="close_work"></WorkFlowMain>
    </div>
    <div v-show="work_state" class="work-flow-shields"></div>
  </div>
</template>

<script>
import WorkFlowMain from "@/components/WorkFlow/WorkFlowMain.vue";
import eventBus from "@/utils/eventBus";
import SvgIcon from "@/assets/icon/index.vue";
// import dynamics from "@/router/routes/dynamics";
export default {
  name: "TopBar",
  data(){
    return{
      // top_All: dynamics,
      top_All: [
        {
          title:"VIP Club",
          id: "vip"
        },
        {
          title:"Contact",
          id: "contact"
        }
      ],
      mobile_height: false,
      mobile_left: -80,
      work_state: true
    }
  },
  mounted() {
    eventBus.$on("contact_path", this.contact_path)
  },
  props:['top_bar'],
  methods: {
    close_work(){
      this.work_state = false
    },
    detailed_information(hash){
      history.pushState(null, "", `#${hash}`);
      const target = document.querySelector(`#${hash}`);
      if (target) {
        target.scrollIntoView();
      }
    },
    contact_path(){
      this.work_state = false
      setTimeout(() => {
        this.detailed_information("contact")
      },200)
      // this.$router.push("/contact")
    }
  },
  watch: {
    mobile_height(new_value){
      if(new_value){
        this.mobile_left = 0;
      }else {
        this.mobile_left = -80;
      }
    }
  },
  computed: {
  },
  components: {
    SvgIcon,
    WorkFlowMain
  }
}
</script>

<style scoped>
.selection-box{
  width: 95vw;
  font-size: 25rem;
  height: 100rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.top-branding{
  margin-left: 50rem;
  font-size: 35rem;
  user-select: none;
}
.top-branding img{
  width: 200rem;
  object-fit: contain;
}
.navigator-title{
  display: flex;
  height: 100rem;
  align-items: center;
}
.navigator-mobile{
  display: none;
}
.selection-mobile{
  display: none;
}
.selection-box-div{
  height: 100rem;
  line-height: 100rem;
}
.selection-box-div:hover{
  background-color: black;
  color: white;
}
.selection-box-div span{
  padding: 0 30rem;
  user-select:none;
  font-size: 27rem;
}
.top-button{
  background-color: black;
  color: white;
  font-size: 27rem;
  margin-right: 40rem;
  height: 60rem;
  line-height: 60rem;
  padding: 0 30rem;
  border-radius: 30rem;
  user-select: none;
  cursor: pointer;
}
.top-button:hover{
  background-color: rgb(71,71,71);
}
.external-link span{
  color: rgb(171,170,171);
  margin: 10rem 10rem;
}
.external-link svg{
  margin: 10rem 10rem;
}
.work-flow-top{
  position: absolute;
  margin: auto;
  top: 50vh;left: 50vw;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.work-flow-shields{
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  background-color: black;
  opacity: 0.7;
  z-index: 9;
}
@media (max-width: 600px) or (max-aspect-ratio:1/1) {
  .navigator-title {
    display: none;
  }
  .navigator-mobile{
    display: block;
  }
  .navigator-mobile svg{
    font-size: 50rem;
  }
  .selection-mobile{
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 80vw;
    height: 100vh;
    top: 0;
    background-color: white;
    z-index: 8;
    transition: left 0.5s;
  }
  .selection-mobile .top-branding{
    font-size: 30rem;
    text-align: center;
    margin: 15rem 0;
  }
  .selection-mobile .mobile-box-div{
    padding: 25rem 50rem;
  }
}
</style>