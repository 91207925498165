<template>
<div class="layout">
  <div class="layout-top">
    <TopBar :top_bar="top_bar"></TopBar>
  </div>
  <div class="view-html">
    <router-view :key="view_key[$route.fullPath]"></router-view>
  </div>
  <div class="layout-bottom">
    <BottomBar></BottomBar>
  </div>
</div>
</template>

<script>
import TopBar from "@/layout/TopBar"
import dynamics from "@/router/routes/dynamics.js";
import BottomBar from "@/layout/BottomBar.vue";
export default {
  name: "LayoutIndex",
  components: {
    TopBar,
    BottomBar
  },
  data(){
    return{
      top_bar:dynamics,
      view_key:{}
    }
  },
  created() {
    this.top_bar.forEach(item => {
      this.view_key[item.path] = item.name;
    })
  }
}
</script>

<style scoped>
.layout{
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  font-size: 25rem;
}
.layout-top{
  width: 100vw;
  background-color: #fbf0f0;
  position: fixed;
}
.layout-bottom{
  width: 100%;
}
.view-html{
  width: 100%;
  margin-top: 100rem;
}
</style>