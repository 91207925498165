<script>
import WorkFlowOrder from "@/components/WorkFlow/WorkFlowOrder.vue";
import WorkFlowOrderInfo from "@/components/WorkFlow/WorkFlowOrderInfo.vue";
import WorkFlowOrderReviewOne from "@/components/WorkFlow/WorkFlowOrderReviewOne.vue"
import WorkFlowOrderReviewTwo from "@/components/WorkFlow/WorkFlowOrderReviewTwo.vue";
import SvgIcon from "@/assets/icon/index.vue";
export default {
  name: "WorkFlowMain",
  components: {
    SvgIcon,
    WorkFlowOrder,
    WorkFlowOrderInfo,
    WorkFlowOrderReviewOne,
    WorkFlowOrderReviewTwo
  },
  data(){
    return {
      show_state: "order",
      state_all: {
        WorkFlowOrder: "order",
        WorkFlowOrderInfo: "info",
        WorkFlowOrderReviewOne: "one",
        WorkFlowOrderReviewTwo: "two"
      },
      order: {
        name: "",
        email: "",
        order_id: "???-???????-???????",
        product_asin: "??????????",
        product_name: "??????????????????????????????????????????.",
        address: "??????",
        marketplace: "",
        img_listing: ""
      },
      review_data: {
        star: 0,
        message: ""
      }
    }
  },
  methods: {
    show_state_up_data(state){
      this.show_state = state;
    },
    page_up_data(data){
      this.order = data
      this.show_state_up_data("info")
    },
    want_up_data(){
      this.show_state_up_data("one")
    },
    review_up_data(data){
      this.review_data = data
      this.show_state_up_data("two")
    }
  }
}
</script>

<template>
<div class="work-flow-main">
  <svg-icon icon-class="TablerX" class="work-flow-tabler" @click="$emit('closeWork')"></svg-icon>
  <div class="work-flow-order">
    <div v-show="show_state === state_all.WorkFlowOrder">
      <WorkFlowOrder @flowOrder="page_up_data"></WorkFlowOrder>
    </div>
    <div v-show="show_state === state_all.WorkFlowOrderInfo">
      <WorkFlowOrderInfo @wantUp="want_up_data" :order="order"></WorkFlowOrderInfo>
    </div>
    <div v-show="show_state === state_all.WorkFlowOrderReviewOne">
      <WorkFlowOrderReviewOne @leftClick="show_state_up_data('info')" @rightClick="review_up_data" :order="order"></WorkFlowOrderReviewOne>
    </div>
    <div v-show="show_state === state_all.WorkFlowOrderReviewTwo">
      <WorkFlowOrderReviewTwo :review_data="review_data" :order="order"></WorkFlowOrderReviewTwo>
    </div>
  </div>
</div>
</template>

<style scoped>
.work-flow-main{
  background-color: #000000;
  box-shadow: 0 0 50rem 20rem rgba(255, 253, 253, 0.5);
  padding: 50rem 100rem;
  border-radius: 10rem;
}
.work-flow-tabler{
  color: white;
  position: absolute;
  right: -10rem;
  top: -10rem;
  font-size: 35rem;
  background-color: rgb(61, 66, 71);
  cursor: pointer;
  padding: 6rem;
  border-radius: 50%;
}
@media (max-width: 600px) {
  .work-flow-main{
    padding: 50rem 40rem;
  }
  .work-flow-tabler{
    font-size: 50rem;
  }
}
@media (max-width: 500px) {
  .work-flow-main{
    padding: 50rem 25rem;
  }
}
</style>