import request from "@/utils/request_python";

export function order_consult(data) {
    return request({
        url: '/order_consult',
        method: 'post',
        data
    })
}
export function star_up_data(data) {
    return request({
        url: '/star_up_data',
        method: 'post',
        data
    })
}