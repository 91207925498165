import layout from '@/layout/index.vue'
import dynamics from "@/router/routes/dynamics"

const common = [
    {
        path: '/',
        name: 'init',
        redirect:'/layout'
    },
    {
        path: '/layout',
        name: 'layout',
        component: layout,
        redirect:'/home',
        children: dynamics
    }
]

export default common