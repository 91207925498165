<script>
import SvgIcon from "@/assets/icon/index.vue";
import birthdayImg from "@/assets/img/birthday.png"
export default {
  name: "WorkFlowOrderInfo",
  components: {SvgIcon},
  data(){
    return{
      birthdayImg,
    }
  },
  props: ["order"],
  methods: {
    want_click(){
      this.$emit("wantUp")
    }
  }
}
</script>

<template>
<div class="work-flow-order-info-main">
  <div class="work-flow-order-info-title">
    <div class="work-flow-order-info-true">
      <svg-icon icon-class="true"></svg-icon>
    </div>
    <div>
      <span>Congratulations!</span>
      <span>Your warranty has already been activated!</span>
    </div>
  </div>
  <hr>
  <div class="work-flow-order-info-show">
    <span>And you Selected to Get Our Exclusive Gift</span>
    <div class="work-flow-order-info-all">
      <div class="work-flow-order-info-outer">
        <span class="work-flow-order-info-head">Your Order Info</span>
        <div style="width: 80%;display: flex;flex-direction: column;">
          <span class="work-flow-order-info-caption">Order ID:</span>
          <span class="work-flow-order-info-descriptive">{{order.order_id}}</span>
          <span class="work-flow-order-info-caption">Product ASIN:</span>
          <span class="work-flow-order-info-descriptive">{{order.product_asin}}</span>
          <span class="work-flow-order-info-caption">Product Name:</span>
          <span class="work-flow-order-info-descriptive" style="-webkit-line-clamp: 2;-webkit-box-orient: vertical;">{{order.product_name}}</span>
          <span class="work-flow-order-info-caption">Address:</span>
          <span class="work-flow-order-info-descriptive">{{order.address}}</span>
        </div>
      </div>
      <div class="work-flow-order-info-outer work-flow-order-info-outer2">
        <span class="work-flow-order-info-head">What You Can Get</span>
        <img :src="birthdayImg" alt="unloaded" style="margin: 10rem 0">
        <span class="work-flow-order-info-head">One-year extended warranty</span>
      </div>
      <div class="work-flow-order-info-outer">
        <span class="work-flow-order-info-head">About Lifetime Warranty</span>
        <div style="width: 80%;display: flex;flex-direction: column;">
          <span class="work-flow-order-info-caption3">Free, no hidden fees, no extra charges</span>
          <span class="work-flow-order-info-caption3">* Get it in 4 easy steps (usually less than 5 minutes)</span>
          <span class="work-flow-order-info-caption3">* Take effect immediately after receiving</span>
          <span class="work-flow-order-info-caption3">* Enjoy VIP after-sales service during product use</span>
        </div>
      </div>
    </div>
    <div class="work-flow-order-info-button" @click="want_click">I WANT IT</div>
  </div>
</div>
</template>

<style scoped>
.work-flow-order-info-main{
  display: flex;
  flex-direction: column;
}
.work-flow-order-info-title{
  display: flex;
  align-items: center;
  color: white;
  font-size: 30rem;
  margin: 20rem 10rem;
}
.work-flow-order-info-true{
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(to bottom, rgb(116, 189, 72), rgb(107, 232, 39), rgb(101, 182, 59));
  width: 60rem;
  height: 60rem;
  font-size:45rem;
  border-radius: 50%;
  margin-right: 15rem;
}
.work-flow-order-info-title div{
  display: flex;
  flex-direction: column;
}
.work-flow-order-info-show{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.work-flow-order-info-show > span{
  color: rgb(254,137,4);
  font-size: 20rem;
  margin: 15rem 0;
}
.work-flow-order-info-all{
  width: 1350rem;
  display: flex;
  justify-content: space-between;
  color: rgb(0, 0, 0);
}
.work-flow-order-info-all > div{
  width: 420rem;
  height: 450rem;
  background-color: #5b5a67;
  border-radius: 40rem;
}
.work-flow-order-info-outer{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40rem 0;
  box-sizing: border-box;
}
.work-flow-order-info-outer2 span{
  width: 65%;
  text-align: center;
}
.work-flow-order-info-outer2 img{
  width: 55%;
}
.work-flow-order-info-head{
  font-size: 27rem;
}
.work-flow-order-info-caption {
  font-weight: bold;
}
.work-flow-order-info-caption3 {
  font-weight: 550;
  font-size: 24rem;
  margin: 5rem 0;
}
.work-flow-order-info-descriptive{
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
}
.work-flow-order-info-button{
  background-image: linear-gradient(to bottom,#FCB211,#FE8702);
  height: 70rem;
  padding: 0 80rem;
  line-height: 70rem;
  text-align: center;
  user-select: none;
  border-radius: 15rem;
  cursor: pointer;
  margin: 30rem 0;
  font-size: 30rem;
}
@media (max-width: 600px) or (max-aspect-ratio:1/1) {
  .work-flow-order-info-title > div:nth-child(2){
    width: 360rem;
  }
  .work-flow-order-info-all{
    width: 420rem;
    max-height: 450rem;
    flex-direction: column;
    overflow: auto;
  }
  .work-flow-order-info-all > div{
    margin-top: 20rem;
  }
}
</style>