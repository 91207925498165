<script>
export default {
  name: "BottomBar",
  data(){
    return {
      clause:[
        {
          title:"Terms of Service",
          path:""
        },
        {
          title:"Return and Refund",
          path:""
        },
        {
          title:"Shipping Policy",
          path:""
        },
        {
          title:"Privacy Policy",
          path:""
        }
      ]
    }
  },
  computed: {
  }
}
</script>

<template>
<div class="bottom-bar">
  <div class="bottom-clause">
    <div class="bottom-2024">© 2024 ColEaze.com Inc. or its affiliates. All rights reserved.</div>
  </div>
</div>
</template>

<style scoped>
.bottom-bar{
  width: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}
.bottom-clause{
  display: flex;
  width: 70%;
  margin-top: 40rem;
  justify-content: center;
}
@media (max-width: 600px) {
  .bottom-bar{
  }
  .bottom-2024{
    display: none;
  }
  .bottom-clause{
    width: 100%;
    justify-content: center;
    margin: 20rem 0;
  }
}
</style>