let funRelus = null
export default function (binding){
    funRelus = function (binding, funRelus=undefined)  {
        const message = binding.value || 'This is an alert!'; // 默认消息
        if (funRelus && funRelus.timeState){
            clearTimeout(funRelus.timeoutOld)
            funRelus.alertBoxOld.style.left = '60%';
            funRelus.alertBoxOld.style.opacity = 0;
            setTimeout(() => {
                document.body.removeChild(funRelus.alertBoxOld);
            }, 300); // 等待过渡效果结束后移除
        }
        // 创建提示框元素
        const alertBox = document.createElement('div');
        alertBox.textContent = message;
        alertBox.style.position = 'fixed';
        alertBox.style.top = '-80rem';
        alertBox.style.left = '50%';
        alertBox.style.transform = 'translateX(-50%)';
        if (binding.state === "info"){
            alertBox.style.backgroundColor = '#368ff4'; // 红色背景
            alertBox.style.color = '';
        }else if (binding.state === "error"){
            alertBox.style.backgroundColor = '#f44336'; // 红色背景
            alertBox.style.color = 'white';
        }else if (binding.state === "success"){
            alertBox.style.backgroundColor = '#368ff4'; // 红色背景
            alertBox.style.color = '#65f436';
        }
        alertBox.style.padding = '20rem 40rem';
        alertBox.style.fontSize = '25rem';
        alertBox.style.borderRadius = '15rem';
        alertBox.style.textAlign = 'center';
        alertBox.style.zIndex = '1000';
        alertBox.style.transition = 'all 0.3s ease-in-out';

        // 将提示框添加到页面
        document.body.appendChild(alertBox);
        setTimeout(() => {
            alertBox.style.top = "10rem"
        },50)
        let funRelusNb = {
            alertBoxOld: alertBox,
            timeState: true
        }
        // 3秒后自动隐藏提示框
        funRelusNb.timeoutOld = setTimeout(() => {
            alertBox.style.left = '60%';
            alertBox.style.opacity = 0;
            setTimeout(() => {
                funRelusNb.timeState = false
                document.body.removeChild(alertBox);
            }, 300); // 等待过渡效果结束后移除
        }, 3000);
        return funRelusNb;
    }(binding, funRelus)
}