// import VIPClubMain from '@/components/Home/VIPClub.vue'
// import ContactMain from '@/components/Home/ContactMain.vue'
import HomeMain from "@/components/Home/HomeMain.vue";
const dynamics = [
    // {
    //     path: '/vip',
    //     name: 'VIPClubMain',
    //     component: VIPClubMain,
    //     meta: { title:'VIP Club' },
    // },
    // {
    //     path: '/contact',
    //     name: 'ContactMain',
    //     component: ContactMain,
    //     meta: { title:'Contact' },
    // }
    {
        path: '/home',
        name: 'HomeMain',
        component: HomeMain
    }
]
export default dynamics