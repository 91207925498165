<script>
import SvgIcon from "@/assets/icon/index.vue";

export default {
  name: "LoadIcon",
  components: {SvgIcon}
}
</script>

<template>
  <i class="load-main">
    <svg-icon icon-class="load"></svg-icon>
  </i>
</template>

<style scoped>
.load-main{
  display: inline-block;
  animation: turn .5s linear infinite;
}
@keyframes turn {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(72deg);
  }

  40% {
    transform: rotate(144deg);
  }

  60% {
    transform: rotate(216deg);
  }

  80% {
    transform: rotate(288deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>