import {createRouter, createWebHistory} from "vue-router";
import common from "@/router/routes/common.js";


const router = createRouter({
    history: createWebHistory(),
    routes: [...common],
})

export default router
