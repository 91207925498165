export default function (value, event, color = '#fff'){
    // 获取鼠标点击的位置
    const { clientX: x, clientY: y } = event;
    const scrollY = window.scrollY || document.documentElement.scrollTop; // 获取页面滚动高度

    // 创建提示框 DOM 元素
    const tooltip = document.createElement('div');
    tooltip.innerText = value || '提示信息'; // 提示内容
    tooltip.style.position = 'absolute';
    tooltip.style.top = `${y + 10 + scrollY}px`; // 加上滚动高度，确保正确显示
    tooltip.style.left = `${x + 10}px`; // 鼠标右侧 10px
    tooltip.style.padding = '8px';
    tooltip.style.backgroundColor = 'rgba(56,81,87,0.62)';
    tooltip.style.color = color;
    tooltip.style.borderRadius = '4px';
    tooltip.style.fontSize = '25rem';
    tooltip.style.pointerEvents = 'none';
    tooltip.style.zIndex = '1000';
    tooltip.style.opacity = '0';
    tooltip.style.transition = 'opacity 0.3s ease';

    // 将提示框添加到页面
    document.body.appendChild(tooltip);

    // 延迟显示效果
    setTimeout(() => {
        tooltip.style.opacity = '1';
    }, 0);

    // 定时销毁提示框
    setTimeout(() => {
        tooltip.style.opacity = '0';
        setTimeout(() => {
            document.body.removeChild(tooltip);
        }, 300); // 与 CSS 过渡时间匹配
    }, 1500); // 显示 1 秒后消失
}