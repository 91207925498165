import axios from 'axios'
import alert from "@/utils/common/alert";

// 创建一个axios实例
const service = axios.create({
  baseURL: '/api_py', // 基础url从环境变量中获取
  timeout: 10000 // 十秒钟之内请求要发完,否则取消
})

// 请求拦截器
service.interceptors.request.use(
  config => {
        //请求之前执行的代码
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
        if (response.status !== 200){
          alert({value: response.data.message || 'Error', state: "error"})
          return Promise.reject(new Error(response.data.message || 'Error'))
        }else {
          return response.data
        }
  },
  error => {
    // console.log('err' + error)
      const message = error.response.data.message ? error.response.data.message : error.response.statusText
      alert({value: message, state: "error"})
    return Promise.reject(error)
  }
)

export default service
